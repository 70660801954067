import './App.css';
import { useState } from 'react';
import axios from 'axios';
import { Turnstile } from '@marsidev/react-turnstile'

function App() {
  return (
    <div className="App">
      <h1>Price tracker</h1>
      <GetStats/>
    </div>
  );
}

function GetStats() {
  const [link, setLink] = useState('')
  const [email, setEmail] = useState('')
  const [msg, setMsg] = useState('')
  const [status, setStatus] = useState('')
  const [preMsg, setPreMsg] = useState('')


  return (
    <div>
      
      <label>Link: <input name="lnk" value={link} onChange={(e) => setLink(e.target.value)}/></label>
      Email: <input name="eml" value={email} onChange={(e) => setEmail(e.target.value)}/>
      <Turnstile siteKey='0x4AAAAAAAFUJ7G7NwkZSGHB' onSuccess={() => setStatus('solved')}/>
      <button onClick={
        () => 
        {if (status === 'solved') {axios.post(
        "https://price-tracker.dakshsrivastava.com/api/",
        {"link2": link, "email": email})
        .then(setPreMsg('Subscribed!'))
        .catch((err) => {setPreMsg('Please ensure your link and email were valid')})}
        else {setPreMsg('Please verify you are human')
        
      }
      setMsg(preMsg)
      }
      }>
      Subscribe</button>
      <button onClick={
        () => 
        {if (status==='solved') {axios.post("https://price-tracker.dakshsrivastava.com/api/delete/", 
        {"link2": link, "email": email})
        .then(setPreMsg('Unsubscribed!'))
        .catch((err) => {setPreMsg('Please ensure your link and email were valid')})}
        else {setPreMsg('Please verify you are human')}
      setMsg(preMsg)
      }
      }>
      Unsubscribe</button>
      <p id="Verify">{msg}</p>
      
    </div>
  )
}

export default App;
